import React from 'react'
import styled from 'styled-components'
import { When } from '../styling/when'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
`

const GridItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`

const GridBottleName = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

  padding: 0 5%;

  ${When.Medium} {
    padding: 0;
  }
`

const GridBottleMeasurement = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`

const StyledGatsbyImg = styled(Img)`
  /* display: block !important;
  margin: 0 auto 16px auto !important;
  padding-bottom: 0 !important; */
  height: 340px;
  margin-bottom: 12px;
  /* width: 50%; */
  /* padding */
  /* width: 126px; */
  /* 
  ${When.Medium} {
    width: 190px;
  }

  ${When.Large} {
    width: 100% !important;
  } */
`

const itemAnim = {
  hidden: { opacity: 0, y: 30 },
  show: {
    opacity: 1,
    y: 0,
    transition: { y: { duration: 0.2, type: 'tween' } },
  },
}

export default ({
  bottle: {
    id,
    name,
    fields: { slug, categorySlug },
    fluid,
    measurement,
  },
  onLoad,
  onError,
}) => {
  return (
    <GridItem variants={itemAnim} key={id}>
      <StyledLink
        to={`/${categorySlug}/bottles/${slug}?type=${id.split('-')[1]}`}
      >
        <StyledGatsbyImg
          loading="eager"
          onLoad={onLoad}
          onError={onError}
          fluid={fluid}
          imgStyle={{ objectFit: 'contain' }}
        />
        <GridBottleName>{name}</GridBottleName>
        <GridBottleMeasurement>{measurement} ml</GridBottleMeasurement>
      </StyledLink>
    </GridItem>
  )
}
