import { graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { Layout } from '../components'
import {
  Arrow2Left,
  Bottle,
  File,
  Package,
  Shield,
  Truck1,
  Truck2,
} from '../components/icons'
import { white } from '../styling/colors'
import { When } from '../styling/when'

const Wrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${When.Medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${When.Large} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${When.Medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${When.Large} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 48px;
    align-items: flex-start;
  }
`
const Boxx = styled.div`
  display: none;

  ${When.Large} {
    display: block;
    max-width: 256px;
    width: 100%;
    background-color: #fafafa;
    /* position: relative; */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Box2 = styled.div`
  max-width: 256px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 24px;
  @media screen and (max-width: 375px), {
    margin-left: 28px;
    align-self: flex-start;
  }
  ${When.Large} {
    margin-top: 0;
  }
`

const Kmc = styled.p`
  font-style: normal;
  font-weight: 600px;
  font-family: Poppins;
  max-width: 106px;
  width: 100%;
  font-size: 24px;
  padding: 69px 75px;
  color: #212121;
`
const Circle = styled.div`
  max-width: 64px;
  height: 64px;
  background-color: #212121;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Title = styled.h3`
  font-size: 18px;
  margin-top: 12px;
  margin-bottom: 8px;
`
const Text = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400px;
  margin-top: 0;
`

const SimpleNav = styled.div`
  display: flex;
  align-items: flex-start;
`

const BackButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 28px;

  &:hover {
    cursor: pointer;
  }

  ${When.Large} {
    margin-left: 0;
  }
`

const Back = styled.p`
  font-size: 14px;
  color: #212121;
  margin-top: 34px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  margin-bottom: 35px;
  margin-left: 8px;
`

export default function Delivery({ location }) {
  return (
    <Layout location={location}>
      <SimpleNav>
        <BackButton to="/logistics">
          <Arrow2Left />
          <Back>Back</Back>
        </BackButton>
      </SimpleNav>
      <Wrapper1>
        <Boxx>
          <Kmc>KMC Logistics</Kmc>
        </Boxx>

        <Box2>
          <Circle>
            <Shield />
          </Circle>
          <Title>Reliable and Secure</Title>
          <Text>
            The company KMC Glass delivers products through its own service -
            KMC Logistics. Which ensures safe and timely transportation of the
            product;
          </Text>
        </Box2>

        <Box2>
          <Circle>
            <File />
          </Circle>
          <Title>Customs</Title>
          <Text>
            Our team offers a full range of custom services that are an integral
            part of KMC Glass services;
          </Text>
        </Box2>
      </Wrapper1>

      {/* second line */}
      <Wrapper2>
        <Box2>
          <Circle>
            <Bottle />
          </Circle>
          <Title>Individual Projects</Title>
          <Text>
            Consulting services to help you design and manufacture a mold
            suitable for your product;
          </Text>
        </Box2>
        <Box2>
          <Circle>
            <Package />
          </Circle>
          <Title>Stocking Infrastructure</Title>
          <Text>
            The company has its own warehousing infrastructure where supplies
            are constantly updated. This even eliminates delays in order
            delivery and the occurrence of deficits in the event of variable
            demand.
          </Text>
        </Box2>
        <Box2>
          <Circle>
            <Truck2 />
          </Circle>
          <Title>Complex Delivery</Title>
          <Text>
            We allow you to order and collect a variety of products, regardless
            of the geography of its production;
          </Text>
        </Box2>
      </Wrapper2>
    </Layout>
  )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
