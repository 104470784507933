import React from 'react'
import styled from 'styled-components'
import {
  CategorySlider,
  Layout,
  CoverSlider,
  MapSVG,
  ContactForm,
} from '../components'
import { When } from '../styling/when'
import { useMediaMatch } from '../util'
import { navigate } from '@reach/router'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

const ThinArrow = props => {
  return (
    <svg
      width={93}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_thin_arrow)">
        <path
          d="M89.97 6.91H.75M7.6 13.08l-6.67-6a.22.22 0 010-.33l6.67-6"
          stroke="#AF8D08"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.97 9a2.09 2.09 0 100-4.18 2.09 2.09 0 000 4.18z"
          fill="#AF8D08"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_thin_arrow">
          <path fill="#fff" d="M0 0h92.05v13.83H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const Snap = styled.div`
  height: ${({ mobileHeight }) => mobileHeight};
  width: 100%;
  display: flex;
  flex-direction: column;

  ${When.Large} {
    height: ${({ largeHeight }) => largeHeight};
  }
`

const Snap1 = styled(Snap)`
  ${When.Medium} {
    min-height: 644px;
  }
`

const SliderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
  margin: 0 auto;
  position: relative;
  width: 100%;
  transition: width 0.1s linear;
`

const AboutUsTitleContainer = styled.div`
  display: ${({ onlyDesktop }) => (onlyDesktop ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 25px;
  margin-bottom: 10px;
  ${When.Medium} {
    margin-left: 0;
    display: ${({ onlyMobile }) => (onlyMobile ? 'none' : 'flex')};
    margin-bottom: 25px;
  }
`

const AboutSectionTitle = styled.h2`
  margin: 0;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  text-align: center;
  color: #212121;
  ${When.Large} {
    font-size: 104px;
  }
`

const AboutSectionTitlePart = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  text-align: center;
  color: #af8d08;
  ${When.Large} {
    font-size: 42px;
  }
`

const Snap2 = styled(Snap)`
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 50px;
  max-width: 820px;
  ${When.Medium} {
    flex-direction: row;
  }
  ${When.Large} {
    margin-top: 100px;
    max-width: 1120px;
  }
`

const Snap2ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Snap2Image = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 25px;
  ${When.Medium} {
    width: 400px;
  }
  ${When.Large} {
    height: 644px;
    width: 544px;
  }
`

const Snap2Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  margin: 0 auto;
  ${When.Medium} {
    margin: 0;
    width: 100%;
    margin-left: 60px;
    max-width: 340px;
    justify-content: center;
  }

  ${When.Large} {
    margin-left: 120px;
  }
`
const SnapSeperator = styled.div`
  height: 3px;
  background-color: #af8d08;
  width: calc(100% - 50px);
  margin: 0 auto;
  ${When.Medium} {
    width: 100%;
  }
`

const Snap2Description = styled.p`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  margin-top: 50px;
  margin-bottom: 30px;
  ${When.Large} {
    margin-top: 0;
    margin-bottom: 88px;
  }
`

const Snap2Button = styled.button`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  flex-direction: row-reverse;
  & svg {
    transform: rotate(180deg);
  }
  padding: 0;
  ${When.Medium} {
    margin-left: 0;
  }
`

const Snap2ButtonText = styled.span`
  display: inline-block;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #af8d08;
  margin-right: 14px;
`

const Snap3 = styled(Snap)`
  margin: 0 auto;
  max-width: 1120px;
  margin-bottom: 50px;
  ${When.Medium} {
    margin-bottom: 120px;
  }
`

const Snap3TitleContainer = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  margin-bottom: 6px;
`

const Snap3TitlePart1 = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 52px;
  color: #212121;
  ${When.Large} {
    font-size: 104px;
    line-height: 110px;
  }
`

const Snap3TitlePart2 = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 38px;
  text-align: center;
  color: #af8d08;
  ${When.Large} {
    font-size: 42px;
    line-height: 46px;
  }
`

const Snap3TitlePart3 = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 46px;
  color: #212121;
  ${When.Large} {
    font-size: 104px;
    line-height: 110px;
  }
`

const Snap3TitleDescContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 50px);
  ${When.Medium} {
    justify-content: space-between;
    flex-direction: row;
  }
`

const Snap3Description = styled.h2`
  margin: 0;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  text-align: left;
  ${When.Medium} {
    max-width: 220px;
    font-size: 26px;
    line-height: 30px;
  }

  ${When.Large} {
    margin-top: 40px;
    max-width: 260px;
    font-size: 30px;
    line-height: 45px;
  }
`

const Snap4 = styled(Snap)`
  margin: 0 auto;
  position: relative;
  max-width: 1120px;
  margin-bottom: 50px;
  min-height: 410px;
  ${When.Medium} {
    // margin-bottom: 120px;
  }
`

const Snap4Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: calc(100% - 50px);
  margin-top: -40px;
  ${When.Medium} {
    margin-top: 0;
    align-items: flex-end;
  }

  ${When.Large} {
    min-height: 820px;
    justify-content: center;
  }
`

const Snap4TitleContainer = styled.h2`
  display: ${({ onlyDesktop }) => (onlyDesktop ? 'none' : 'flex')};
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 24px;
  width: calc(100% - 50px);
  ${When.Medium} {
    display: ${({ onlyMobile }) => (onlyMobile ? 'none' : 'flex')};
    align-items: flex-end;
    margin-bottom: 60px;
  }
`

const Snap4TitlePart1 = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
  color: #af8d08;
  ${When.Medium} {
  }
`

const Snap4TitlePart2 = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  color: #212121;
  ${When.Large} {
    font-size: 104px;
    line-height: 60px;
  }
`

const Snap4MapContainer = styled.div`
  height: 410px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  jusitfy-content: center;
  & svg {
    height: 100%;
    min-width: 800px;
    margin-left: -310px;
  }

  ${When.Medium} {
    & svg {
      width: 100%;
      margin-left: unset;
      z-index: -1;
    }
    position: absolute;
    left: 50%;
    transform: translateX(-84%);
  }

  ${When.Large} {
    width: 1600px;
    height: 820px;
  }
`

const Snap4Description = styled.p`
  margin: 0;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  max-width: 484px;
  ${When.Medium} {
  }
`

const Snap5 = styled(Snap)`
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 50px;
  max-width: 820px;
  ${When.Medium} {
    flex-direction: row-reverse;
  }
  ${When.Large} {
    margin-top: 40px;
    max-width: 1120px;
  }
`

const Snap5TitleContainer = styled.div`
  display: ${({ onlyDesktop }) => (onlyDesktop ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 25px;
  margin-bottom: 10px;
  ${When.Medium} {
    margin-top: 40px;
    margin-left: 0;
    display: ${({ onlyMobile }) => (onlyMobile ? 'none' : 'flex')};
    margin-bottom: 25px;
  }
`

const Snap5TitlePart1 = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
  color: #af8d08;
`

const Snap5TitlePart2 = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  color: #212121;
  ${When.Large} {
    font-size: 104px;
    line-height: 110px;
  }
`

const Snap5TitlePart3 = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
  color: #af8d08;
`

const Snap5TitlePart4 = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  color: #212121;
  ${When.Large} {
    font-size: 104px;
    line-height: 110px;
  }
`

const Snap5ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Snap5Image = styled.img`
  width: 100%;
  height: 480px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 30px;
  ${When.Medium} {
    height: 600px;
    width: 400px;
  }
  ${When.Large} {
    height: 760px;
    width: 590px;
  }
`

const Snap5Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  margin: 0 auto;
  max-width: 320px;
  ${When.Medium} {
    max-width: unset;
    margin: 0;
    margin-right: 50px;
    width: 100%;
    justify-content: flex-start;
  }
`

const Snap5Description = styled.p`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  margin-top: 40px;
  margin-bottom: 30px;
  ${When.Large} {
    margin-top: 20px;
    margin-bottom: 110px;
  }
`

const Snap5Button = styled.button`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  & svg {
    transform: rotate(180deg);
  }
  ${When.Medium} {
    margin-right: 0;
  }
  flex-direction: row-reverse;
  padding: 0;
`

const Snap5ButtonText = styled.span`
  display: inline-block;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #af8d08;
  margin-right: 14px;
`

const Snap6 = styled(Snap)`
  margin: 0 auto;
  margin-top: 50px;
  ${When.Large} {
    height: 700px;
    max-width: 1330px;
  }
`

const StyledThinArrow = styled(ThinArrow)``

export default function Home({ location }) {
  const { medium, large } = useMediaMatch()
  let showSliderItems = 1

  if (medium) {
    showSliderItems = 3
  }

  if (large) {
    showSliderItems = 4
  }
  const { t, i18n } = useTranslation()

  return (
    <Layout
      location={location}
      style={{ maxHeight: 'unset', height: 'max-content' }}
      contentStyle={{ maxWidth: 'unset' }}
    >
      <Snap1 mobileHeight="488px" largeHeight="min(800px, calc(100vh - 88px))">
        <CoverSlider />
      </Snap1>
      <Snap2>
        <AboutUsTitleContainer onlyMobile>
          <AboutSectionTitle>{t('about')}</AboutSectionTitle>
          <AboutSectionTitlePart>{t('us')}</AboutSectionTitlePart>
        </AboutUsTitleContainer>
        <Snap2ImageContainer>
          <Snap2Image src="/images/home-page-about/cover.webp" />
          <SnapSeperator />
        </Snap2ImageContainer>
        <Snap2Content>
          <AboutUsTitleContainer onlyDesktop>
            <AboutSectionTitle>{t('about')}</AboutSectionTitle>
            <AboutSectionTitlePart>{t('us')}</AboutSectionTitlePart>
          </AboutUsTitleContainer>
          <Snap2Description>{t('aboutUsShort')}</Snap2Description>
          <Snap2Button onClick={() => navigate('/about')}>
            <StyledThinArrow />
            <Snap2ButtonText>{t('seeMore')}</Snap2ButtonText>
          </Snap2Button>
        </Snap2Content>
      </Snap2>
      <Snap3>
        <Snap3TitleDescContainer>
          <Snap3TitleContainer>
            <Snap3TitlePart1>{t('perfection')}</Snap3TitlePart1>
            <Snap3TitlePart2>{t('fromThe')}</Snap3TitlePart2>
            <Snap3TitlePart3>{t('inGlass')}</Snap3TitlePart3>
          </Snap3TitleContainer>
          <Snap3Description>{t('decorateInnov')}</Snap3Description>
        </Snap3TitleDescContainer>
        <SliderContainer>
          <CategorySlider showSliderItems={showSliderItems} />
        </SliderContainer>
      </Snap3>
      <Snap4>
        <Snap4TitleContainer onlyMobile>
          <Snap4TitlePart1>{t('our')}</Snap4TitlePart1>
          <Snap4TitlePart2>{t('geography')}</Snap4TitlePart2>
        </Snap4TitleContainer>
        <Snap4MapContainer>
          <MapSVG />
        </Snap4MapContainer>
        <Snap4Contents>
          <Snap4TitleContainer onlyDesktop>
            <Snap4TitlePart1>{t('our')}</Snap4TitlePart1>
            <Snap4TitlePart2>{t('geography')}</Snap4TitlePart2>
          </Snap4TitleContainer>
          <Snap4Description>{t('geographyInfo')}</Snap4Description>
        </Snap4Contents>
      </Snap4>
      <Snap5>
        <Snap5TitleContainer onlyMobile>
          <Snap5TitlePart1>{t('our')}</Snap5TitlePart1>
          {/* <Snap5TitlePart2>Products</Snap5TitlePart2> */}
          {/* <Snap5TitlePart3>and</Snap5TitlePart3> */}
          <Snap5TitlePart4>{t('services')}</Snap5TitlePart4>
        </Snap5TitleContainer>
        <Snap5ImageContainer>
          <Snap5Image src="/images/home-page-products-and-services/cover.webp" />
          <SnapSeperator />
        </Snap5ImageContainer>
        <Snap5Content>
          <Snap5TitleContainer onlyDesktop>
            <Snap5TitlePart1>{t('our')}</Snap5TitlePart1>
            {/* <Snap5TitlePart2>Products</Snap5TitlePart2> */}
            {/* <Snap5TitlePart3>and</Snap5TitlePart3> */}
            <Snap5TitlePart4>{t('services')}</Snap5TitlePart4>
          </Snap5TitleContainer>
          <Snap5Description>
            {t('servicesText1')}
            <br />
            <br />
            {t('servicesText2')}
            <br />
            <br />
            {t('servicesText3')}
          </Snap5Description>
          <Snap5Button onClick={() => navigate('/decorations')}>
            <StyledThinArrow />
            <Snap5ButtonText>{t('seeMore')}</Snap5ButtonText>
          </Snap5Button>
        </Snap5Content>
      </Snap5>
      <Snap6>
        <ContactForm />
      </Snap6>
    </Layout>
  )
}
export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
