import React, { useState } from 'react'
import { Globe } from '../icons'
import styled from 'styled-components'
import { Popover } from '@material-ui/core'
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next'
import { black, white } from '../../styling/colors'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const GlobeWrapper = styled.div`
  padding-right: 5px;
`

const DisplayText = styled.span`
  font-size: 14px;
  color: white;
`

const PopoverMessage = styled.div`
  width: 100px;
  height:100px;
`
const LanguageList = styled.ul`
  padding:0;
  margin:0;
  width:100%;
  height:100%;
  list-style:none;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`
const LanguageListItem = styled.li`
  width:100%;
  height:50%;
  display:flex;
  align-items:center;
  justify-content:center;
  &:hover {
    font-weight:bold;
    font-size:20px;
  }
`
export default function LanguageSwitcher() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const { i18n } = useTranslation()
  const { languages, originalPath } = useI18next()

  return (
    <Container
      onMouseEnter={e => setAnchorEl(e.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
    >
      <Popover
        style={
          {
            overflow:'clip'
          }
        }
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <PopoverMessage>
          <LanguageList>
            {languages.map(lng => (
              <LanguageListItem>
                <Link to={originalPath} language={lng} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', textTransform:'uppercase'}}>
                  {lng}
                </Link>
              </LanguageListItem>
            ))}
          </LanguageList>
        </PopoverMessage>
      </Popover>
      <GlobeWrapper>
        <Globe />
      </GlobeWrapper>
      <DisplayText style={{textTransform:'uppercase'}}>{i18n.language}</DisplayText>
    </Container>
  )
}
