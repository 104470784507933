import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/noto-sans-georgian/300.css'
import '@fontsource/noto-sans-georgian/400.css'
import '@fontsource/noto-sans-georgian/500.css'
import '@fontsource/noto-sans-georgian/600.css'
import '@fontsource/abril-fatface/400.css'
import '@fontsource/comfortaa/400.css'
import '@fontsource/cinzel/400.css'
import '@fontsource/lobster/400.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/teko/400.css'

export default createGlobalStyle`
  ${normalize}  

  body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", "Noto Sans Georgian";
  }

  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    color: inherit;
  }
`
