import { graphql } from 'gatsby'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Layout, LayoutFluid } from '../components'
import { When } from '../styling/when'
import Img from 'gatsby-image'
import { black, white } from '../styling/colors'
import ComingSoon from '../components/comming-soon'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const TitleSection = styled.div`
  margin-top: 64px;
  position: relative;
  color: black;
  margin-bottom: 104px;
`

const LogosSection = styled.div`
  margin: 0 auto;
  position: relative;
  flex: 1;
  justify-content: center;
  ${When.Large} {
    width: 100%;
  }
`

const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* grid-template-columns: auto auto auto;
  grid-column-gap: 40px;
  grid-row-gap: 54px; */
`

const LogoContainer = styled.div`
  flex: 1 0 24%;
  padding-bottom: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UpperBox = styled.div`
  /* display: none; */

  ${When.Large} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
  }
`
const Title = styled.h1`
  color: ${black};
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;

  ${When.Large} {
    display: block;
    font-size: 48px;
    font-family: 'Poppins', 'Noto Sans Georgian';

    margin: 0;
  }
`
const SubText = styled.p`
  color: ${black};
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  ${When.Large} {
    display: block;
    font-size: 14px;
  }
`

const horizontalSlideritemListContainerStyles = css`
  /* add space to make inner element box shadows visible */
  /* padding: 100px 0; */
`

export default function Partners({ data, location }) {
  const partners = useMemo(() => {
    return data.allStrapiPartners.edges.map(({ node }) => ({
      name: node.name,
      image: node.logo?.localFile?.childImageSharp?.fixed,
    }))
  }, [data])
  const { t, i18n } = useTranslation()

  const enable = true
  return (
    <Layout location={location}>
      {enable && (
        <>
          <TitleSection>
            <UpperBox>
              <Title>
                {t('our')} {t('partners')}
              </Title>
              <SubText>{t('logisticsShortText')}</SubText>
            </UpperBox>
          </TitleSection>
          <LogosSection>
            <Logos>
              {partners.map(({ name, image }) => (
                <LogoContainer key={name}>
                  <Img fixed={image} alt={name} />
                </LogoContainer>
              ))}
            </Logos>
          </LogosSection>
        </>
      )}
      {!enable && <ComingSoon />}
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiPartners {
      edges {
        node {
          name
          logo {
            localFile {
              childImageSharp {
                fixed(width: 160) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
